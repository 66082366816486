import React, { FC } from 'react';
import { useUIDSeed } from 'react-uid';

import { CheckIcon } from '../icons/CheckIcon';
import { Colors } from '../utils/style-utils';
import { Typography } from './Typography';

const rules = [
  {
    text: 'One lowercase letter',
    test: (str = ''): boolean => /[a-z]/.test(str),
  },
  {
    text: 'One uppercase letter',
    test: (str = ''): boolean => /[A-Z]/.test(str),
  },
  {
    text: 'One numeric character',
    test: (str = ''): boolean => /[0-9]/.test(str),
  },
  {
    text: 'One special symbol !@#$%^&*()',
    test: (str = ''): boolean => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str),
  },
  {
    text: 'Min 6 characters',
    test: (str = ''): boolean => str.length >= 6,
  },
];

export const PasswordRules: FC<{ password: string }> = ({ password }) => {
  const seed = useUIDSeed();
  return (
    <ul style={{ listStyle: 'none' }}>
      {rules.map((rule) => (
        <li className="flex items-center" key={seed(rule.text)}>
          <CheckIcon
            className="mr-4 inline-block"
            fill={rule.test(password) ? Colors.Green500 : '#BDBDBD'}
          />
          <Typography>{rule.text}</Typography>
        </li>
      ))}
    </ul>
  );
};
