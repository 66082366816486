import { Link } from 'gatsby';
import Dialog from 'rc-dialog';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FiCheckCircle, FiHelpCircle, FiX, FiXOctagon } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { FilerInstructions } from '../components/FilerInstructions';
import { Input } from '../components/Input';
import { Label } from '../components/Label';
import { PasswordInput } from '../components/PasswordInput';
import { PasswordRules } from '../components/PasswordRules';
import { H4, H5 } from '../components/Typography';
import { createUser as createUserAction } from '../lib/actions/me';
import { Paths } from '../paths';
import { getBodyMessage } from '../utils/function-utils';
import { Colors } from '../utils/style-utils';
import { HelpText } from './styles';

const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
`;

interface Props {
  onComplete?: () => void;
  okText?: string;
  subtitle?: string;
}

interface FormValues {
  email: string;
  password: string;
  key: string;
  bar: string;
}

export const SignupForm: FC<Props> = ({ onComplete, okText = 'Submit', subtitle }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      key: '',
      bar: '',
    },
  });
  const password = watch('password', '');
  const [modalOpen, setModalVisibility] = useState(false);
  const dispatch = useDispatch();

  async function onSubmit(values: FormValues) {
    try {
      await dispatch(
        createUserAction({
          email: values.email,
          password: values.password,
          bar_number: values.bar,
          licence_key: values.key,
        }),
      );
      toast.success('Your user was created successfully. Please check your email.', {
        icon: <FiCheckCircle className="min-w-full w-8" size={32} color="#3CB043" />,
      });
      onComplete && onComplete();
      reset();
    } catch (err) {
      toast.error(getBodyMessage(err), {
        icon: <FiXOctagon className="min-w-full w-8" size={32} color="#D0312D" />,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Create a free account</H4>

        {subtitle && <H5 className="mb-6 text-center">{subtitle}</H5>}

        <Input
          label="Email Address"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>
                {errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}
              </HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <PasswordInput
          label="Password"
          className="mb-4"
          error={!!errors.password}
          inputStyle={{ color: '#333' }}
          {...register('password', {
            required: true,
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&\[\]\{\}_\-\(\)])[A-Za-z\d@#$!%*?&\[\]\{\}_\-\(\)]{6,}$/gm,
          })}
        />

        <PasswordRules password={password} />

        <Input
          label="Bar number"
          className="mb-4"
          error={!!errors.key}
          helpText={errors.key && <HelpText>Bar number is required</HelpText>}
          inputStyle={{ color: '#333' }}
          placeholder="Bar number"
          {...register('bar', { required: true })}
        />

        <Input
          label={
            <div className="flex items-baseline">
              <Label className="mr-4">e-Filer key</Label>
              <button
                onClick={() => setModalVisibility(true)}
                type="button"
                className="border-0 bg-transparent">
                <FiHelpCircle />
              </button>
            </div>
          }
          className="mb-4"
          error={!!errors.key}
          helpText={errors.key && <HelpText>Key is required</HelpText>}
          inputStyle={{ color: '#333' }}
          placeholder="0000-0000-0000-0000"
          {...register('key', { required: true, pattern: /([A-F0-9\-])+/ })}
        />

        <div className="flex justify-end mt-4">
          <div className="flex flex-col mr-auto">
            <Link to={Paths.SignIn}>Already have an account? Sign In</Link>
            <Link to={Paths.RequestVerification}>Resend verification email</Link>
          </div>

          <Button type="submit" disabled={isSubmitting}>
            {okText}
          </Button>
        </div>

        <Dialog
          closable
          maskClosable
          animation="zoom"
          maskAnimation="fade"
          wrapClassName="modal-center"
          closeIcon={<FiX color={Colors.Blue900} />}
          onClose={() => setModalVisibility(false)}
          visible={modalOpen}
          bodyStyle={{ padding: 0 }}
          style={{ maxWidth: '90vw', width: '100%' }}>
          <Container className="flex flex-col flex-1 bg-white w-full" style={{ maxWidth: '100%' }}>
            <H4 className="mb-12 text-center lg:text-left" style={{ fontSize: 20 }}>
              How it Works
            </H4>
            <FilerInstructions />
          </Container>
        </Dialog>
      </Container>
    </form>
  );
};
